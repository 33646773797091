.header-wrapper {
  background-color: #0047ba;
}

.logo {
  max-width: 100%;
}

.logo-area {
  max-width: 200px;
  margin: 16px 0;
}

.site-title {
  color: #ffffff;
  font-size: 26px;
}

.site-info-section {
  justify-content: right;
}

.headerHelpIcon {
  height: 50px;
  width: 50px;
  padding-left: 10px;
}