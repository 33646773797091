@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "Cocogoose Pro";
  src: url("../fonts/cocogoose/Cocogoose Pro-trial.ttf"),
    url("../fonts/cocogoose/Cocogoose Pro Ultralight-trial.ttf");
}
@font-face {
  font-family: "Cocogoose Pro Ultralight";
  src: url("../fonts/cocogoose/Cocogoose Pro Ultralight-trial.ttf");
}
@font-face {
  font-family: "Cocogoose Pro Thin";
  src: url("../fonts/cocogoose/Cocogoose Pro Thin-trial.ttf");
}
@font-face {
  font-family: "Cocogoose Pro Semilight";
  src: url("../fonts/cocogoose/Cocogoose Pro Semilight-trial.ttf");
}
@font-face {
  font-family: "Cocogoose Pro Light";
  src: url("../fonts/cocogoose/Cocogoose Pro Light-trial.ttf");
}

/*---Theme Style----*/

body {
  margin: 0;
  /* font-family: "Cocogoose Pro Light", sans-serif; */
  font-family: sans-serif;
  font-size: 110%;
  color: #272c36;
}

.no-font {
  font-family: initial;
}

/*----Step Css---*/
.MuiStepLabel-labelContainer span {
  font-family: "Cocogoose Pro", sans-serif;
  font-size: 18px;
  letter-spacing: initial;
}
.MuiStepLabel-iconContainer svg {
  font-size: 40px;
}

.MuiStepLabel-iconContainer svg.Mui-active {
  color: #0047ba;
}
.MuiStepLabel-iconContainer svg.Mui-completed {
  color: #0047ba;
}

/*---(END) Theme Style---*/
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-vertically-center {
  display: flex;
  align-items: center;
}

.container-padding-vertical {
  padding-top: 30px;
  padding-bottom: 30px;
}
.text-hover {
  font-weight: bold;
}

/*---Form Inputs---*/
.input-set-wrapper {
  margin-top: 20px;
}
.inputSet {
  margin-top: 5px !important;
}
.form-conatiner {
  position: relative;
}
.loader-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.6);
  z-index: 999;
  justify-content: center;
  align-items: center;
}
/* .text-hover {
  font-weight: bold;
} */
/**
FM module css
*/
.text-hover:hover span {
  font-weight: bold;
}

.text-hover .Mui-checked,
.text-hover .Mui-checked + span {
  font-weight: bold;
}

.select-wrapper {
  overflow: hidden;
}

.renderNotification {
  font-family: sans-serif;
}

/*---input charecter description----*/
.input-defination {
  color: gray;
  font-size: 14px;
  margin: 5px 0;
  font-style: italic;
}

.disabledButton {
  cursor: not-allowed !important;
}

.errorMessage {
  font-size: 10px;
  color: red;
  margin-top: 5px;
}
/* common margin css for textField */
.textField-gap {
  margin-top: 15px;
  margin-bottom: 15px;
}
